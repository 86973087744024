// Copyright 2022, Imprivata, Inc.  All rights reserved.

import type React from 'react';
import { Layout } from 'antd';
import { useTranslation } from 'react-i18next';
import SetupStep from '../../components/SetupStep';
import InvalidSessionSvg from '../../assets/icons/astra_setup_full_page_error.svg';
import classes from '../steps/steps.module.less';
import SetupLayout from '../../components/SetupLayout';

const { Content } = Layout;

const InvalidSession: React.FC = function InvalidStateStep() {
  const { t } = useTranslation();

  return (
    <SetupLayout renderTitle={false}>
      <SetupStep
        data-testid="full-page-unable-to-continue"
        className={classes.invalidSessionLayout}
        showRightPart={false}
      >
        <Content className={classes.invalidContent}>
          <img alt="invalidState" src={InvalidSessionSvg} />

          <div className="invalid-session-message h3-header">
            {t('messages.errors.invalid-session-header' as never)}
          </div>
          <div className="invalid-content-primary-body primary-body">
            {t('messages.errors.invalid-session-primary' as never)}
          </div>
        </Content>
      </SetupStep>
    </SetupLayout>
  );
};

export default InvalidSession;
