// Copyright 2022, Imprivata, Inc.  All rights reserved.

import type React from 'react';
import { useCallback } from 'react';
import { Modal } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { reloadConfirmShown } from '../../store/app/selectors';
import { reloadAppPageCancel, reloadAppPageConfirm } from '../../store/app/actions';

const ReloadPageConfirmModal: React.FC = function ReloadPageConfirmModal() {
  const { t } = useTranslation();
  const visible = useSelector(reloadConfirmShown);
  const dispatch = useDispatch();
  const onOk = useCallback(() => dispatch(reloadAppPageConfirm()), [dispatch]);
  const onCancel = useCallback(() => dispatch(reloadAppPageCancel()), [dispatch]);

  return (
    <Modal
      title={t('messages.errors.service-unavailable.title')}
      open={visible}
      onOk={onOk}
      onCancel={onCancel}
      okText={t('actions.reload-page')}
      cancelText={t('actions.cancel')}
      closable={false}
      okType={'danger'}
      destroyOnClose
      maskClosable={false}
    >
      <p>{t('messages.errors.service-unavailable.description')}</p>
    </Modal>
  );
};

export default ReloadPageConfirmModal;
