// Copyright 2024, Imprivata, Inc.  All rights reserved.

import { getUpdatedUrls } from './utils/urlUtils';

interface AppEnvConfig {
  // Required variables
  API_URL: string;
  ADMIN_UI_URL: string;
  // Optional variables
  HTTPS?: boolean;
  PUBLIC_URL?: string;
  LOCAL_TRACING_URL?: string;
  TRACING_SOLUTION_TYPE?: string;
  OTLP_TRACING_URL?: string;
  ZIPKIN_TRACING_URL?: string;
  COLLECT_TRACING?: string;
}

export const MOCKSERVER_DEFAULT_URL = 'http://localhost:3020';

export interface URLConfig {
  apiUrl?: string;
  tracingUrl?: string;
  adminUrl?: string;
}

let envConfig: AppEnvConfig;

export function _getEnvVariable(
  window: Window,
  env: ImportMetaEnv,
  name: string,
  defaultValue?: string,
): string | undefined {
  const reactAppEnvName = `REACT_APP_${name}`;
  if (window._appConfig !== undefined && Object.prototype.hasOwnProperty.call(window._appConfig, name)) {
    return window._appConfig[name];
  }

  if (Object.prototype.hasOwnProperty.call(env, reactAppEnvName) || Object.prototype.hasOwnProperty.call(env, name)) {
    return env[reactAppEnvName] ?? env[name];
  }

  return defaultValue;
}

export function _initAppConfig(window: Window, env: ImportMetaEnv): AppEnvConfig {
  const { apiUrl, adminUrl, tracingUrl } = getUpdatedUrls();
  // file deepcode ignore MissingArgument
  return {
    API_URL: apiUrl || (_getEnvVariable(window, env, 'API_URL', apiUrl) as string),
    ADMIN_UI_URL: adminUrl || (_getEnvVariable(window, env, 'ADMIN_UI_URL', '/users/') as string),
    PUBLIC_URL: _getEnvVariable(window, env, 'PUBLIC_URL', '') as string,
    LOCAL_TRACING_URL: _getEnvVariable(window, env, 'LOCAL_TRACING_URL', '') as string,
    HTTPS: _getEnvVariable(window, env, 'HTTPS') === 'true',
    TRACING_SOLUTION_TYPE: _getEnvVariable(window, env, 'TRACING_SOLUTION_TYPE') as string,
    COLLECT_TRACING: _getEnvVariable(window, env, 'COLLECT_TRACING'),
    OTLP_TRACING_URL: _getEnvVariable(window, env, 'OTLP_TRACING_URL', tracingUrl),
  };
}

export default function getConfig(): AppEnvConfig {
  if (!envConfig) {
    envConfig = _initAppConfig(window, import.meta.env);
  }
  return envConfig;
}
