// Copyright 2022, Imprivata, Inc.  All rights reserved.

import type React from 'react';
import { Typography, Layout } from 'antd';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button } from '@imprivata-cloud/astra-ui-component-library';
import SetupStep from '../../components/SetupStep';
import SetupStepHeader from '../../components/SetupStepHeader';
import { ENTRA_ID_AD_CONNECT_ROUTE } from '../../constants/routes';
import backgroundImage from '../../assets/astra_setup_b.svg';
import classes from './steps.module.less';

const { Paragraph } = Typography;
const { Content } = Layout;

const AddTrustedLocationStep: React.FC = function AddTrustedLocationStep() {
  const { t } = useTranslation();

  return (
    <SetupStep
      data-testid="step-add-trusted-location"
      className={classes.addTrustedLocation}
      rightPartBackground={backgroundImage}
    >
      <SetupStepHeader title={t('steps.add-trusted-location.title-label')} />
      <Content data-testid="step-description">
        <Paragraph className="primary-body">
          <Trans i18nKey="steps.add-trusted-location.label.paragraph-1" />
          <a
            className={classes.hyperLink}
            target="_blank"
            rel="noopener noreferrer"
            href={t('steps.add-trusted-location.label.paragraph-1-link-url')}
          >
            {t('steps.add-trusted-location.label.paragraph-1-link-title')}
          </a>
        </Paragraph>
        <Paragraph className="primary-body">
          <Trans i18nKey="steps.add-trusted-location.label.paragraph-2" />
        </Paragraph>
        <Paragraph className="primary-body">
          <ul className={classes.styledList}>
            <li>
              <Trans i18nKey="steps.add-trusted-location.label.list-item-1" />
            </li>
            <li>
              <Trans i18nKey="steps.add-trusted-location.label.list-item-2" />
            </li>
            <li>
              <Trans i18nKey="steps.add-trusted-location.label.list-item-3" />
            </li>
          </ul>
        </Paragraph>
        <Paragraph className="primary-body">
          <Trans i18nKey="steps.add-trusted-location.instructional-text" />
        </Paragraph>
        <Paragraph className={classes.button}>
          <Link to={ENTRA_ID_AD_CONNECT_ROUTE}>
            <Button
              type="primary"
              size="large"
              astraButtonType={['major']}
              autoFocus
              label={t('steps.add-trusted-location.next-btn')}
              data-testid="proceed-btn"
            />
          </Link>
        </Paragraph>
      </Content>
    </SetupStep>
  );
};

export default AddTrustedLocationStep;
