// Copyright 2022, Imprivata, Inc.  All rights reserved.

import type React from 'react';
import { Layout } from 'antd';
import SetupHeader from './SetupHeader';

import classes from './SetupLayout.module.less';

const SetupLayout: React.FC<{
  renderTitle: boolean;
  children: React.ReactNode;
}> = function SetupLayout(props) {
  return (
    <Layout data-testid="setup-layout" className={classes.setupLayout}>
      <SetupHeader renderTitle={props.renderTitle} />
      <div data-testid="setup-content" className={classes.setupContent}>
        {props.children}
      </div>
    </Layout>
  );
};

export default SetupLayout;
