// Copyright 2024, Imprivata, Inc.  All rights reserved.

import type React from 'react';
import { useState, useCallback, type MutableRefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import { Button } from '@imprivata-cloud/astra-ui-component-library';
import DataProcessingAddendum from './DataProcessingAddendum';
import QuestionSvg from '../assets/icons/question.svg?react';
import classes from './DataProcessingAddendumModal.module.less';

const DataProcessingAddendumModal: React.FC<{
  visibleDpaModal: boolean;
  pending: boolean;
  closeDpaModal: () => void;
  onSubmitClick: () => void;
  dpaAccepted: MutableRefObject<boolean>;
}> = function SetupHeader({ visibleDpaModal, pending, closeDpaModal, onSubmitClick, dpaAccepted }) {
  const { t } = useTranslation();

  const [visibleYouMust, setVisibleYouMust] = useState(false);
  const openYouMust = () => setVisibleYouMust(true);
  const closeYouMust = () => setVisibleYouMust(false);

  const closeBothModals = () => {
    closeYouMust();
    closeDpaModal();
  };

  const [bottomReached, setBottomReached] = useState(false);

  interface ScrollEvent extends React.UIEvent<HTMLDivElement> {
    target: HTMLDivElement & EventTarget;
  }

  const handleScroll = useCallback((e: ScrollEvent) => {
    if (
      e.target.scrollHeight - e.target.scrollTop <=
      e.target.clientHeight + 1 // +1 to compensate fractions
    ) {
      setBottomReached(true);
    }
  }, []);

  return (
    <>
      <Modal
        open={visibleDpaModal}
        closable={false}
        destroyOnClose
        width={1000}
        footer={
          <div className={classes.mainModalFooter}>
            <Button
              label={t('dpa.agree')}
              disabled={!bottomReached || pending}
              type="primary"
              astraButtonType={['major']}
              data-testid="dpa-agree-btn"
              onClick={() => {
                dpaAccepted.current = true;
                onSubmitClick();
              }}
            />
            <Button
              label={t('dpa.decline')}
              disabled={pending}
              astraButtonType={['major']}
              data-testid="dpa-decline-btn"
              onClick={openYouMust}
            />
          </div>
        }
      >
        <div className={classes.mainModal} onScroll={handleScroll} data-testid="dpa-container">
          <DataProcessingAddendum />
        </div>
      </Modal>
      {/*** You Must Modal ***/}
      <Modal open={visibleYouMust} destroyOnClose closable={false} footer={null}>
        <div className={classes.modalYouMust}>
          <QuestionSvg />
          <h1>{t('dpa.decline-modal-title')}</h1>
          <div>{t('dpa.decline-modal-text')}</div>
          <div className={classes.youMustFooter}>
            <Button
              label={t('dpa.go-back')}
              type="primary"
              data-testid="dpa-you-must-go-back-btn"
              onClick={closeYouMust}
            />
            <Button label={t('dpa.decline')} data-testid="dpa-you-must-decline-btn" onClick={closeBothModals} />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DataProcessingAddendumModal;
