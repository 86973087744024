// Copyright 2024, Imprivata, Inc.  All rights reserved.

import shortUUID from 'short-uuid';
import { MOCKSERVER_DEFAULT_URL, Tracer, _getUrls, TracingSolutionType } from '@imprivata-cloud/common';
import getConfig from '../appEnvConfig';
import { getBaseUrl } from '../utils/build';
export * from './constants';

const tracingOptions = (() => {
  // Fix tracing urls, allowing for local development
  const { tracingUrl } = _getUrls();
  const correctedTracingUrl =
    tracingUrl?.includes('localhost') || window.location.hostname === 'localhost'
      ? [tracingUrl || MOCKSERVER_DEFAULT_URL, getBaseUrl(), 'tracing']
          .map((u) => u.replace(/^\/+/g, ''))
          .filter(Boolean)
          .join('/')
      : tracingUrl;

  const appConfig = getConfig();

  // // NOTE: Currently Tracer checks these values ONLY for "null".
  const config = {
    OTLP_TRACING_URL: appConfig.OTLP_TRACING_URL || correctedTracingUrl,
    ZIPKIN_TRACING_URL: appConfig.ZIPKIN_TRACING_URL || correctedTracingUrl,
    COLLECT_TRACING: appConfig.COLLECT_TRACING,
    TRACING_SOLUTION_TYPE: appConfig.TRACING_SOLUTION_TYPE,
  };

  return {
    logToConsole: true,
    otlpExportUrl: config.OTLP_TRACING_URL,
    zipkinExportUrl: config.ZIPKIN_TRACING_URL,
    collectSpans: config.COLLECT_TRACING === 'true',
    tracingSolutionType: config.TRACING_SOLUTION_TYPE,
  };
})();

const generateWorkflowId = () => shortUUID.uuid();

export const workflowId = generateWorkflowId();

console.debug('Initializing tracing [init-setup-ui]: ', {
  options: tracingOptions,
  workflowId,
});

export const tracer = new Tracer(
  'init-setup-ui',
  workflowId,
  {
    logToConsole: true,
    collectSpans: true, // used for local development and CI
    otlpExportUrl: tracingOptions.otlpExportUrl,
  },
  undefined,
  TracingSolutionType.DYNATRACE,
);
