// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { reloadAppPageCancel, reloadAppPageConfirm, serviceGoneSignal } from './actions';
import type { RootAction } from '../rootAction';

export const pageReloadModalReducer = createReducer<boolean, RootAction>(false)
  .handleAction(serviceGoneSignal, () => true)
  .handleAction([reloadAppPageConfirm, reloadAppPageCancel], () => false);

export default combineReducers({
  pageReloadModal: pageReloadModalReducer,
});
