// Copyright 2022, Imprivata, Inc.  All rights reserved.

import type React from 'react';
import clsx from 'clsx';

import classes from './SetupStep.module.less';

interface SetupStepProps {
  'data-testid'?: string;
  showRightPart?: boolean;
  rightPartBackground?: string;
  className?: string;
  children: React.ReactNode;
}

const SetupStep: React.FC<SetupStepProps> = function SetupStep(props) {
  const { 'data-testid': testId, className, rightPartBackground, showRightPart = true } = props;

  return (
    <div data-testid={testId} className={clsx(classes.stepLayout, className)}>
      <div className={classes.leftPart}>{props.children}</div>
      {showRightPart && (
        <div className={classes.rightPart} style={{ backgroundImage: `url(${rightPartBackground})` }} />
      )}
    </div>
  );
};

export default SetupStep;
