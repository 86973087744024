// Copyright 2022, Imprivata, Inc.  All rights reserved.

import type { Store, Dispatch } from 'redux';
import { configureStore as configureReduxStore } from '@reduxjs/toolkit';
import { createEpicMiddleware, type Epic } from 'redux-observable';
import { BehaviorSubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import type { History } from 'history';
import type { RootAction } from './rootAction';
import rootReducer, { type RootState } from './rootReducer';
import rootEpic from './rootEpic';

export type AppState = ReturnType<typeof rootReducer>;
export type RootStore = Store<AppState>;

export type ConfigOptions = {
  initialState?: Partial<RootState>;
  isProduction?: boolean;
  history?: History;
};

export function configureStore(options?: ConfigOptions): RootStore {
  const { initialState, isProduction, history } = options ?? {};
  const epicMiddleware = createEpicMiddleware<RootAction, RootAction, RootState>({ dependencies: { history } });

  const store = configureReduxStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(epicMiddleware),
    preloadedState: initialState,
    devTools: !isProduction ? { trace: true, traceLimit: 25 } : false,
  });

  if (!isProduction && import.meta.hot) {
    const epic$ = new BehaviorSubject<Epic<RootAction, RootAction, RootState>>(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      rootEpic,
    );
    // Every time a new epic is given to epic$ it
    // will unsubscribe from the previous one then
    // call and subscribe to the new one because of
    // how switchMap works
    const hotReloadingEpic: Epic<RootAction, RootAction, RootState> = (...args) =>
      epic$.pipe(switchMap((epic) => epic(...args)));

    epicMiddleware.run(hotReloadingEpic);

    import.meta.hot.accept('./rootReducer', () => {
      store.replaceReducer(rootReducer);
    });
    import.meta.hot.accept('./rootEpic', () => {
      // eslint-disable-next-line @typescript-eslint/no-var-requires, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      const nextRootEpic = require('./rootEpic').default;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      epic$.next(nextRootEpic);
    });
  } else {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    epicMiddleware.run(rootEpic);
  }

  return store;
}

export type AppDispatch = Dispatch<RootAction>;
