// Copyright 2024, Imprivata, Inc.  All rights reserved.

import type React from 'react';
import './DataProcessingAddendum.less';

// Please follow this format to make the BE happy
export const DPA_DOCUMENT_NAME = 'DPA-v2024_1.html';

const DataProcessingAddendum: React.FC = function DataProcessingAddendum() {
  return (
    <>
      <div>
        <div className="data-processing-addendum">
          <h1 className="title">Data Processing Addendum</h1>
          <p>
            Imprivata, Inc. (hereinafter referred as “Imprivata”) and Customer (hereinafter referred as “Customer”) have
            entered into a contractual agreement (the “Principal Agreement”) pursuant to which Imprivata may Process
            certain Personal Data on behalf of Customer in connection with Customer’s use of the Imprivata Products
            and/or Services (each as defined below). This Data Processing Addendum (“DPA”) describes certain data
            processing and transfer obligations of the parties. In the event of any inconsistency between the DPA and
            the Principal Agreement, the DPA shall control. In the event of inconsistency between the DPA and, if
            applicable, the Standard Contractual Clauses (as defined below), the Standard Contractual Clauses shall
            govern. Except as modified herein, the terms of the Principal Agreement shall remain in full force and
            effect.
          </p>
          <h2>Definitions</h2>
          <p>In this DPA, the following terms shall have the meanings set out below:</p>
          <p>
            <em>
              <strong>"Subprocessor"</strong>
            </em>{' '}
            means any Processor (including any third party) appointed by Imprivata to process Customer Personal Data on
            behalf of the Customer.
          </p>
          <p>
            <em>
              <strong>
                "Process/Processing/Processed", "Controller", "Processor", "Data Subject", "Personal Data", "Special
                Categories of Personal Data", "Sensitive Data", "Supervisory Authority",
              </strong>
            </em>{' '}
            and any further definition not included in the Principal Agreement or this DPA shall have the same meaning
            as in appliable Data Protection Law.
          </p>
          <p>
            <em>
              <strong>“Data Protection Law”</strong>
            </em>{' '}
            means any binding laws, rules, or regulations which apply to a party relating to the use of Personal Data
            (including, without limitation, the privacy of electronic communications).
          </p>
          <p>
            <em>
              <strong>"Third Country" or "Third Countries"</strong>
            </em>{' '}
            means any country outside of a country in which the Data Protection Laws restrict transfers of Personal Data
            to destinations outside of that country, except where the Data Protection Laws and applicable regulatory
            authorities of the originating country adopted an adequacy decision regarding the Data Protection Laws of
            the destination country such that transfers of Personal Data to that destination country are not restricted.
          </p>
          <p>
            <em>
              <strong>"Customer Personal Data"</strong>
            </em>{' '}
            means the data described in <a href="#schedule-1">Schedule 1</a> and any other Personal Data processed by
            Imprivata on behalf of the Customer pursuant to, or in connection with, the Principal Agreement.
          </p>
          <p>
            <em>
              <strong>"Personal Data Breach"</strong>
            </em>{' '}
            means a breach of security leading to the accidental or unlawful destruction, loss, alteration, unauthorised
            disclosure of, or access to, Customer Personal Data transmitted, stored or otherwise processed that
            compromises the security, confidentiality or integrity of such Customer Personal Data.
          </p>
          <p>
            <em>
              <strong>"Services"</strong>
            </em>{' '}
            means the services to be supplied by Imprivata to the Customer pursuant to the Principal Agreement.
          </p>
          <p>
            <em>
              <strong>“Products”</strong>
            </em>{' '}
            means the products to be supplied by Imprivata to the Customer pursuant to the Principal Agreement.
          </p>
          <p>
            <em>
              <strong>"Standard Contractual Clauses" or “SCC”</strong>
            </em>{' '}
            means the standard contractual clauses for the transfer of personal data annexed to European Commission
            Implementing Decision (EU) 2021/914 of 4 June 2021.
          </p>
          <p>
            <em>
              <strong>“UK Addendum”</strong>
            </em>{' '}
            means United Kingdom (“UK”) Information Commissioner’s (“ICO”) International Data Transfer Addendum to the
            EU Commission Standard Contractual Clauses Version B1.0 in force 21 March 2022.
          </p>
          <h2>Data Processing Terms</h2>
          <ol>
            <li>
              In the course of providing the Products and/or Services to the Customer pursuant to the Principal
              Agreement, Imprivata may Process Customer Personal Data on behalf of the Customer, pursuant to the terms
              of this DPA. Imprivata and Customer shall each comply with their respective obligations applicable to each
              under the Data Protection Laws and this DPA.
            </li>
            <li>
              Imprivata shall maintain all the technical and organizational measures set forth in{' '}
              <a href="#schedule-1">Schedule 1</a>.
            </li>
          </ol>
          <h2>Processing of Customer Personal Data</h2>
          <ol>
            <li>
              The purposes of Processing of Customer Personal Data and the instructions for Processing are as described
              in the Principal Agreement, including <a href="#annex-1">Schedule 1, Annex 1</a> to this DPA, and any
              other exhibits, statements of work or addenda attached to or otherwise incorporated into the Principal
              Agreement (the <strong>“Permitted Purpose”</strong>).
            </li>
            <li>
              Imprivata shall only Process Customer Personal Data for the Permitted Purpose(s). Imprivata shall not
              Process or disclose or permit the disclosure of the Customer Personal Data to any third party other than
              as set forth herein or as otherwise agreed in writing by the parties, unless such Processing or disclosure
              is required by the applicable Data Protection Law to which Imprivata is subject ("Mandated Processing”).
              Imprivata shall not, as part of its business operations, ‘sell’ or ‘share’ (as such terms may be
              specifically defined in applicable Data Protection Laws) Customer Personal Data. To the extent required by
              applicable Data Protection Laws, Imprivata certifies that it understands the foregoing restrictions and
              will comply with them. Imprivata shall, to the extent permitted by Data Protection Laws, inform Customer
              of any Mandated Processing before so Processing or disclosing the Customer Personal Data and comply with
              the Customer’s instructions to minimize, to the extent reasonably possible, the scope of the Processing or
              disclosure. To the extent required by applicable Data Protection Laws, Imprivata will inform Customer if,
              in its opinion, an instruction from Customer infringes Data Protection Laws. Imprivata is not responsible
              for performing legal research and/or for providing legal advice to Customer in connection with the
              foregoing.
            </li>
          </ol>
          <h2>Non–Disclosure</h2>
          <ol>
            <li>
              Imprivata shall restrict access to Customer Personal Data to those individuals who require access to the
              relevant Customer Personal Data for the Permitted Purpose.
            </li>
            <li>
              For all individuals who Process Customer Personal Data on Imprivata’s behalf, Imprivata will:
              <ol>
                <li>inform such individuals of the confidential nature of the Customer Personal Data;</li>
                <li>provide training in relation to applicable Data Protection Law; and</li>
                <li>
                  require such individuals to agree to confidentiality terms or ensure the individuals are subject to
                  professional or statutory obligations of confidentiality.
                </li>
              </ol>
            </li>
          </ol>
          <h2>Personal Data Security</h2>
          <ol>
            <li>
              Taking into account the state of the art, the costs of implementation and the nature, scope, context and
              purposes of Processing, as well as the risk of varying likelihood and severity for the rights and freedoms
              of natural persons, Imprivata shall implement appropriate technical and organizational measures to ensure
              a level of Customer Personal Data security appropriate to the risk.
            </li>
            <li>
              In assessing the appropriate level of security, Imprivata shall take into account the risks that are
              presented by Processing, in particular from accidental or unlawful destruction, loss, alteration,
              unauthorized disclosure of, or access to, Customer Personal Data transmitted, stored or otherwise
              Processed.
            </li>
            <li>
              Imprivata may update its security practices from time to time but will not materially decrease the overall
              security of the Products or Services during the term of the Principal Agreement. Such measures shall
              include process for regularly testing, assessing, and evaluating the effectiveness of the measures.
            </li>
          </ol>
          <h2>Subprocessing</h2>
          <ol>
            <li>
              Customer acknowledges and agrees that Imprivata may engage Sub-processors in connection with the provision
              of the Products and/or Services. However, personnel of Imprivata, whether employees or contractors, shall
              not be deemed to be “Subprocessors” for purposes of this DPA. A list of approved Subprocessors as of the
              date of this DPA is specified in <a href="#annex-3">Schedule 1, Annex III</a>. Customer may subscribe to
              receive update alerts when changes are made to the Subprocessor List. For the avoidance of doubt and in
              accordance with Clause 9, Option 2 of the SCC and any similar requirements of other data transfer
              mechanisms, the above constitutes Customer’s general authorization for Imprivata’s engagement of
              Subprocessors and its appointment of additional Subprocessors or replacement of any Subprocessors
              identified in <a href="#annex-3">Schedule 1, Annex III</a>.
            </li>
            <li>
              If Customer reasonably believes that the appointment of a new Sub-processor will have a material adverse
              effect on Imprivata’s ability to comply with applicable Data Protection Laws as a Processor, then Customer
              may notify Imprivata in writing, where such notification must be provided within 7 calendar days following
              the update to the Subprocessor List of its reasonable and objective basis for such belief and where such
              notification includes documented evidence that supports the objection (“Objection”). In the event of an
              Objection, Customer and Imprivata will work together in good faith to find a mutually acceptable
              resolution to address such Objection, including but not limited to reviewing additional documentation
              supporting the Subprocessor’s compliance with the DPA or Data Protection Laws and the Subprocessor’s
              functions relevant to the performance of Products and/or Services. Imprivata shall not disclose any
              Customer Personal Data to that proposed Subprocessor until reasonable steps have been taken to address the
              Objection and Customer has been provided with a reasonable written explanation of the steps taken.
              Notwithstanding the foregoing, Customer consents to the Processing of Customer Personal Data by Imprivata
              solely to perform its obligations under the Principal Agreement, including, but not limited to, any
              Customer Personal Data that may be contained in any support logs for tickets logged for support and
              maintenance by Customer.
            </li>
            <li>
              With respect to each Subprocessor, Imprivata shall:
              <ol>
                <li>
                  carry out adequate due diligence on each Subprocessor regarding its data protection practices,
                  including without limitation, a review of its technical and organisational measures.
                </li>
                <li>
                  include terms in the contract between Imprivata and each Subprocessor which provide Customer no less
                  protection as in this DPA or as required by Data Protection Laws.
                </li>
                <li>
                  insofar as that contract involves the transfer of Customer Personal Data outside of a Third Country,
                  incorporate the SCC, UK Addendum, or such other mechanism as required by Data Protection Laws into the
                  contract between Imprivata and each Subprocessor to ensure the adequate protection of the transferred
                  Customer Personal Data.
                </li>
                <li>
                  be responsible for the Subprocessor’s violations of this DPA or Data Protection Laws in relation to
                  the services such Subprocessor provides to Imprivata to the extent Imprivata would be liable for the
                  same violations under the terms of the Principal Agreement.
                </li>
              </ol>
            </li>
          </ol>
          <h2>Data Subject Rights</h2>
          <ol>
            <li>
              Taking into account the nature of the Processing, Imprivata shall assist the Customer by implementing
              appropriate technical and organisational measures, insofar as this is possible, for the fulfilment of the
              Customer's obligation to respond to requests for exercising Data Subject rights as set forth in applicable
              Data Protection Law (“DSR”).
            </li>
            <li>
              To the extent a Data Subject identifies Customer as the entity that collected its Personal Data, Imprivata
              shall promptly notify the Customer if it receives a DSR relating to the Products or Services covered by
              the Principal Agreement.
            </li>
            <li>
              To the extent Customer, in its use of the Products or Services, does not have the ability to address a
              DSR, then, at Customer’s expense, Imprivata shall cooperate as reasonably requested by the Customer to
              enable the Customer to respond to or comply with any DSR, which shall include:
              <ol>
                <li>
                  the provision of all data requested by the Customer related to the DSR within a timeline as prescribed
                  by the Data Protection Law in each case.
                </li>
                <li>
                  where applicable, providing such assistance as is reasonably requested by the Customer to enable the
                  Customer to comply with the relevant request within the timelines prescribed by the Data Protection
                  Law.
                </li>
              </ol>
            </li>
          </ol>
          <h2>Personal Data Breach</h2>
          <ol>
            <li>
              Imprivata shall notify the Customer without undue delay and, in any case, as soon as practicable, upon
              becoming aware of and confirming a Personal Data Breach for which Imprivata is the source. Imprivata will
              provide the Customer with sufficient information to allow the Customer to meet any obligations to report a
              Personal Data Breach as prescribed by the Data Protection Law. Such notification shall, as a minimum:
              <ol>
                <li>
                  describe the nature of the Personal Data Breach, the categories and numbers of Data Subjects
                  concerned, and the categories and numbers of Customer Personal Data records concerned;
                </li>
                <li>
                  communicate the name and contact details of Imprivata's Data Protection Officer, Privacy Officer or
                  other relevant contact from whom more information may be obtained;
                </li>
                <li>describe the estimated risk and the likely consequences of the Personal Data Breach; and</li>
                <li>describe the measures taken or proposed to be taken to address the Personal Data Breach.</li>
              </ol>
            </li>
            <li>
              Except as required by applicable Data Protection Laws, the obligations set out in Sections 8.1 shall not
              apply to Personal Data Breaches caused by Customer.
            </li>
            <li>
              In the event of a Personal Data Breach, Imprivata shall not inform any third party without first obtaining
              the Customer’s prior written consent, unless notification is required by Data Protection Law, in which
              case Imprivata shall, to the extent permitted by such law, inform the Customer of that legal requirement,
              provide a copy of the proposed notification and consider any comments made by the Customer before
              providing a notification of the Personal Data Breach.
            </li>
            <li>
              Customer and Imprivata shall work together in good faith within the timeframes for Customer to provide
              Personal Data Breach notifications to any third parties in accordance with Data Protection Laws to
              finalize the content of any such notifications. Imprivata’s prior written approval shall be required for
              any statements regarding, or references to, Imprivata made by Customer in any such notifications.
            </li>
          </ol>
          <h2>Customer Responsibilities</h2>
          <ol>
            <li>
              Customer will not share with or transfer to Imprivata (e.g., via use of the Products or Services or
              through screen-sharing or transmitting screenshots in the course of support and maintenance) Special
              Categories of Data or Sensitive Data (including HIPAA Data, as defined in Section 13.4) or other data that
              imposes specific data security or data protection obligations on Imprivata in addition to or different
              from those specified in any documentation or which are not provided as part of the Products or Services
              without Imprivata’s prior written consent or as may be set forth in statements of work or other addenda
              attached to or otherwise incorporated into the Principal Agreement.
            </li>
            <li>
              Customer shall comply with all applicable Data Protection Laws, including: (a) providing all required
              notices and appropriate disclosures to all Data Subjects regarding Customer’s, and Imprivata’s, Processing
              and transfer of Customer Personal Data; (b) obtaining all necessary rights and valid consents, as
              applicable, from Data Subjects, or maintain another valid legal basis in accordance with and as required
              by Data Protection Laws, to permit Processing by Imprivata for the Permitted Purpose or as otherwise
              required or permitted under the Principal Agreement; (c) if the parties have agreed that Customer Personal
              Data will include Special Categories of Data or Sensitive Data, obtaining explicit consents from Data
              Subjects and complying with all applicable Data Protection Laws; and (d) laws that regulate content
              directed toward children (as defined under applicable Data Protection Laws; for example, under 13 years
              old in the United States or under 16 years old in certain other countries).
            </li>
            <li>
              For the avoidance of doubt, Customer’s instructions for the processing of Customer Personal Data shall
              comply with the Data Protection Law. This DPA is Customer’s complete and final instruction to Imprivata in
              relation to Customer Personal Data and additional instructions outside the scope of this DPA would require
              prior written agreement between the parties. Customer acknowledges that Imprivata is reliant on Customer
              for direction as to the extent to which Imprivata is entitled to use and Process Customer Personal Data.
              Consequently, Imprivata will not be liable for any claim brought against Customer by a Data Subject
              arising from any act or omission by Imprivata to the extent that such act or omission resulted from
              Customer’s instructions or Customer’s use of the Services. Customer shall inform Imprivata without undue
              delay and comprehensively about any errors or irregularities related to statutory provisions on the
              processing of Customer Personal Data.
            </li>
          </ol>
          <h2>Deletion or Return of Customer Personal Data</h2>
          <ol>
            <li>
              Upon Customer’s written request and no later than the following events: (i) cessation of Processing of
              Customer Personal Data by Imprivata; or (ii) termination or expiration of the Principal Agreement, except
              as otherwise permitted by applicable Data Protection Laws, Imprivata shall:
              <ol>
                <li>
                  make available to Customer a complete copy of Customer Personal Data then available in the Services in
                  electronic format for ninety (90) days after termination or expiration of the Principal Agreement
                  (“Retrieval Period”); and
                </li>
                <li>
                  after such Retrieval Period, delete and use all reasonable efforts to procure the deletion of all
                  other copies of Customer Personal Data Processed by Imprivata or any Subprocessors, and where deletion
                  is not possible, sufficiently de-identify Customer Personal Data such that it is no longer Personal
                  Data, except if required or permitted by applicable law or for legal, compliance, audit, or security
                  purposes.
                </li>
              </ol>
            </li>
            <li>
              Imprivata may retain Customer Personal Data to the extent required by applicable Data Protection Laws, and
              only to the extent and for such period as required by applicable Data Protection Laws, and always provided
              that Imprivata shall ensure the confidentiality of all such Customer Personal Data and shall ensure that
              such Customer Personal Data is only Processed as necessary for the purpose(s) specified in the applicable
              Data Protection Laws requiring its storage and for no other purpose.
            </li>
          </ol>
          <h2>Information, Audit, and Assistance</h2>
          <ol>
            <li>
              Imprivata shall reasonably assist and support Customer in the event of an investigation by a Supervisory
              Authority, data protection regulator or similar authority, if and to the extent that such investigation
              relates to Imprivata’s Processing of Customer Personal Data.
            </li>
            <li>
              Upon Customer’s written request, Imprivata shall provide to Customer, its authorized representatives
              and/or independent inspection body designated by Customer reasonable access to information related to
              Imprivata’s Processing of Customer Personal Data solely for the purpose of confirming Imprivata’s
              compliance with its obligations under this DPA. Such information shall be Imprivata Confidential
              Information under the Principal Agreement. If no such information is available at the time of Customer’s
              request, or if required by a Supervisory Authority, Imprivata will allow and cooperate in audits as set
              forth below.
            </li>
            <li>
              Upon at least 30 days’ advance written request by Customer, at mutually agreed times and subject to
              Imprivata’s reasonable audit guidelines, Imprivata will provide reasonable assistance and cooperation of
              Imprivata’s relevant staff and provision of additional information solely for the purpose of confirming
              Imprivata’s compliance with its obligations under this DPA (collectively, an “Audit”). For any Audits,
              Customer must provide Imprivata with a proposed audit plan at least two weeks in advance of the audit,
              after which Customer and Imprivata shall discuss in good faith and finalize the audit plan prior to
              commencement of Audit activities. Customer shall reimburse Imprivata for any costs or expenses incurred by
              Imprivata in connection with an Audit.
            </li>
            <li>
              Imprivata reserves the right to restrict access to its proprietary information, including but not limited
              to its network architecture, internal and external test procedures, test results, remediation plans, and
              information or data of or related to Imprivata’s other customers, suppliers, vendors, and partners.
              Customer will use best efforts to minimize disruption to Imprivata’s Services and Imprivata’s premises,
              equipment, personnel or business operations. Customer further agrees that: (W) personnel (or designated
              third parties) performing Audits will be bound by the confidentiality obligations set forth in the
              Principal Agreement; (X) all findings will be deemed Imprivata’s Confidential Information; (Y) Customer
              will share all findings with Imprivata; and (Z) Imprivata will classify and address any Audit findings in
              accordance with Imprivata’s risk management program. Any third party engaged by Customer to conduct an
              Audit must be pre-approved by Imprivata (such approval not to be unreasonably withheld) and sign
              Imprivata’s confidentiality agreement. Imprivata need not give access to its premises for the purposes of
              such an Audit or inspection to any individual unless they produce reasonable evidence of identity and
              authority. Customer is limited to one audit in any 12-month period, except if and as required by a
              competent data protection authority.
            </li>
            <li>
              Without prejudice to the rights granted above, if the requested Audit scope is addressed in a SOC, ISO,
              HIPAA or similar audit report or attestation letter issued by a qualified third party auditor within the
              prior twelve months and Imprivata provides such report or attestation letter to Customer confirming there
              are no known material changes in the controls audited, Customer agrees to accept the findings presented in
              the third party audit report or attestation letter in lieu of requesting an Audit of the same controls
              covered by the report.
            </li>
            <li>
              Taking into account the nature of the Processing and the information available to Imprivata, Imprivata
              shall, upon Customer’s written request, provide Customer with reasonable cooperation and assistance to
              fulfil Customer’s obligations under applicable Data Protection Laws to carry out a data protection impact
              assessment related to Customer’s use of Imprivata’s Products and/or Services. Such cooperation and
              assistance are provided to the extent Customer does not otherwise have access to the relevant information,
              and to the extent such information is available to Imprivata. To the extent required by applicable Data
              Protection Laws, Imprivata shall provide reasonable assistance to Customer in respect of Customer’s prior
              consultations with a Supervisory Authority.
            </li>
          </ol>
          <h2>International Transfers of Customer Personal Data</h2>
          <ol>
            <li>
              Customer consents to the transfer on a global basis of any Customer Personal Data in accordance with its
              instructions under Section 3 as necessary for Imprivata to provide the Products and Services including for
              engineering development, security, troubleshooting and test resources as may be necessary for support and
              maintenance for the Products and/or Services under the Principal Agreement as well as any contact
              information of Customer received by Imprivata under the Principal Agreement.
            </li>
            <li>
              If Customer Personal Data is transferred under the Principal Agreement from a country whose Data
              Protection Laws restrict the transfer of Personal Data to Third Countries, then such transfers shall be
              subject to the protections and provisions of the Standard Contractual Clauses (for which the SCC Appendix
              is attached to this DPA in <a href="#schedule-1">Schedule 1</a>), the UK Addendum for transfers from the
              UK to Third Countries, or other binding and appropriate transfer mechanisms that provide an adequate level
              of protection in compliance with Data Protection Laws.
            </li>
            <li>
              Customer shall be deemed to have signed the SCC in <a href="#annex-1">Annex I</a> in its capacity of “data
              exporter” and Imprivata in its capacity as “data importer.” Module Two or Module Three of the SCC shall
              apply to the transfer depending on whether Customer is Controller of the Customer Personal Data (for
              Module Two) or a Processor of the Customer Personal Data on behalf of its end customer(s) (for Module
              Three). If Module Three applies, Customer hereby notifies Imprivata that Customer is a Processor and the
              instructions shall be as set forth in Section 3. In Clause 7 of the SCCs, the optional docking clause will
              apply. In Clause 9 of the SCCs, Option 2 will apply, and the time period for notice of subprocessor
              changes shall be a reasonable time period. In Clause 11 of the SCCs, the optional language will not apply.
              For purposes of Clauses 17 and 18 of the SCCs, the parties select the Netherlands.
            </li>
            <li>
              The SCC will cease to apply if Imprivata implements an alternative recognized compliance mechanism for the
              lawful transfer of Personal Data in accordance with Data Protection Requirements. In the event of any
              conflict between any terms in the SCC and DPA, the SCC shall prevail to the extent of the conflict.
            </li>
            <li>
              When requested by Customer, Imprivata shall enter into Standard Contractual Clauses and/or such variation
              as the Data Protection Law requires, in respect of any Processing of Customer Personal Data in a Third
              Country.
            </li>
          </ol>
          <h2>General Terms</h2>
          <ol>
            <li>
              Subject to this section, the parties agree that this DPA and the Standard Contractual Clauses shall
              terminate automatically upon termination of the Principal Agreement or expiry or termination of all
              service contracts entered into by Imprivata with the Customer, pursuant to the Principal Agreement,
              whichever is later.
            </li>
            <li>
              Any obligation imposed on Imprivata under this DPA in relation to the Processing of Customer Personal Data
              that by necessity must survive any termination or expiration of this DPA shall so survive.
            </li>
            <li>
              This DPA, excluding the Standard Contractual Clauses, shall be governed by the governing law of the
              Principal Agreement.
            </li>
            <li>
              With regard to the subject matter of this DPA, in the event of inconsistencies between the provisions of
              this DPA and any other agreements between the parties, including but not limited to the Principal
              Agreement, the provisions of this DPA shall prevail with regard to the parties’ data protection
              obligations for Customer Personal Data of a Data Subject. Notwithstanding the foregoing, and solely to the
              extent applicable to any protected health information (as defined under and regulated by the Health
              Insurance Portability and Accountability Act of 1996, as amended ("HIPAA”)) (“HIPAA Data”), if there is
              any inconsistency or conflict between this DPA and a Business Associate Agreement between Imprivata and
              Customer (the “BAA”), then the BAA shall prevail to extent the inconsistency or conflict relates to such
              HIPAA Data.
            </li>
            <li>
              Should any provision of this DPA be invalid or unenforceable, then the remainder of this DPA shall remain
              valid and in force. The invalid or unenforceable provision shall be either (i) amended as necessary to
              ensure its validity and enforceability, while preserving the parties’ intentions as closely as possible
              or, if this is not possible, (ii) construed in a manner as if the invalid or unenforceable part had never
              been contained therein.
            </li>
            <li>
              Indemnification, liability, limitations of liability and any applicable exclusions under this DPA shall be
              governed by the Principal Agreement to the extent not prohibited by Data Protection Laws.
            </li>
            <li>
              Imprivata may share and disclose Customer Personal Data and other data of Customer in connection with, or
              during the negotiation of, any merger, sale of company assets, consolidation or restructuring, financing,
              or acquisition of all or a portion of Imprivata’s business by or to another company, including the
              transfer of contact information and data of customers, partners and end users.
            </li>
          </ol>
        </div>
        <p>&nbsp;</p>
        <div className="appendix">
          <h2 id="schedule-1">
            SCHEDULE 1: <u>APPENDIX TO THE STANDARD CONTRACTUAL CLAUSES</u>
          </h2>
          <h3 id="annex-1">
            <u>ANNEX I</u>
          </h3>
          <h4>LIST OF PARTIES</h4>
          <div>
            <p>
              <strong>Data exporter</strong>
            </p>
            <table>
              <tbody>
                <tr>
                  <td>
                    <strong>Name:</strong>
                  </td>
                  <td>The data exporter is the entity identified as “Customer” in the DPA</td>
                </tr>
                <tr>
                  <td>
                    <strong>Address:</strong>
                  </td>
                  <td>As set forth in the Principal Agreement</td>
                </tr>
                <tr>
                  <td>
                    <strong>Contact person:</strong>
                  </td>
                  <td>As set forth in the Notices provision in the Principal Agreement</td>
                </tr>
                <tr>
                  <td>
                    <strong>Activities relevant to the data transferred under these Clauses:</strong>
                  </td>
                  <td>As set forth in the Principal Agreement</td>
                </tr>
                <tr>
                  <td>
                    <strong>Signature and date:</strong>
                  </td>
                  <td>Refer to DPA or Principal Agreement, as applicable</td>
                </tr>
                <tr>
                  <td>
                    <strong>Role:</strong>
                  </td>
                  <td>
                    Controller, except when processing data on behalf of another entity, in which case data exporter is
                    a Processor
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <p>&nbsp;</p>
            <p>
              <strong>Data importer</strong>
            </p>
            <table>
              <tbody>
                <tr>
                  <td>
                    <strong>Name:</strong>
                  </td>
                  <td>The data importer is the entity identified as “Imprivata” in the DPA</td>
                </tr>
                <tr>
                  <td>
                    <strong>Address:</strong>
                  </td>
                  <td>As set forth in the Principal Agreement</td>
                </tr>
                <tr>
                  <td>
                    <strong>Contact person:</strong>
                  </td>
                  <td>
                    Jeff Kowalski, SVP Legal, and Maria Phillips, Senior Counsel, Privacy &amp; Compliance,{' '}
                    <a href="mailto:dept.legal@imprivata.com">dept.legal@imprivata.com</a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Activities relevant to the data transferred under these Clauses:</strong>
                  </td>
                  <td>As set forth in the Principal Agreement</td>
                </tr>
                <tr>
                  <td>
                    <strong>Signature and date:</strong>
                  </td>
                  <td>Refer to DPA or Principal Agreement, as applicable</td>
                </tr>
                <tr>
                  <td>
                    <strong>Role:</strong>
                  </td>
                  <td>Processor, or Subprocessor if data exporter is a Processor</td>
                </tr>
              </tbody>
            </table>
          </div>
          <h4>DESCRIPTION OF TRANSFER</h4>
          <h5>Categories of data subjects whose personal data is transferred</h5>
          <p>
            <strong>
              <u>
                OneSign, ConfirmID, Identity Governance, GroundControl, FairWarning, Enterprise Access, Customer Connect
              </u>
            </strong>
          </p>
          <p>
            Data exporter may use personal data in its use of the products and services under the Principal Agreement
            concluded with the data importer, the extent of which is determined and controlled by the data exporter in
            its sole discretion, and which may include, but is not limited to personal data relating to the following
            categories of data subjects:
          </p>
          <ul>
            <li>
              exporter’s employees, contractors, collaborators, suppliers, subcontractors, as well as clinicians and/or
              patients (where the data exporter is a healthcare provider).
            </li>
          </ul>
          <p>
            Where the Principal Agreement does not contemplate the processing of personal data of patients, data
            exporter will prevent the sharing or transfer (e.g. via screen-sharing or transmitting screenshots in the
            course of support and maintenance) of personal data of such data subjects to data importer.
          </p>
          <h5>Categories of personal data transferred</h5>
          <p>
            <strong>
              <u>
                OneSign, ConfirmID, Identity Governance, GroundControl, FairWarning, Customer Connect, Enterprise Access
              </u>
            </strong>
          </p>
          <p>
            Due to the nature of the products and services, as well as the support and/or implementation provided to
            data exporter by data importer, there may be both continuous and/or one-off instances of processing. The
            data collection necessary for the usage and one-off instances of processing sent to the importer by the
            exporter for troubleshooting of the product may include one or more of the following:
          </p>
          <ul>
            <li>Personal identification data,</li>
            <li>Employment data,</li>
            <li>Browsing information, and/or</li>
            <li>Device Information.</li>
          </ul>
          <h5>
            Sensitive data transferred (if applicable) and applied restrictions or safeguards that fully take into
            consideration the nature of the data and the risks involved, such as for instance strict purpose limitation,
            access restrictions (including access only for staff having followed specialised training), keeping a record
            of access to the data, restrictions for onward transfers or additional security measures.
          </h5>
          <p>
            <strong>
              <u>OneSign, ConfirmID, Identity Governance, GroundControl, Customer Connect, Enterprise Access</u>
            </strong>
          </p>
          <p>
            Data importer does not require any special categories of data in order to provide the services. Due to the
            nature of the product, sensitive information may be transferred through audit logs generated and sent to the
            data importer. Unless otherwise specified in the Principal Agreement, data exporter shall not provide and
            must receive prior written consent of the data importer before transferring any special categories of data
            or sensitive data to the data importer. In the event that written consent is granted, the following special
            category of data may be shared:
          </p>
          <ul>
            <li>Health data</li>
          </ul>
          <p>
            As a standard practice, data importer will not collect, receive, store, or otherwise transfer any sensitive
            data, however, there are rare cases in which data exporter transfers health information as part of a
            database to data importer, data importer has implemented one or more of the following safeguards:
          </p>
          <ul>
            <li>Anonymization of usage metrics collection</li>
            <li>Single sign-on (SSO)</li>
            <li>Two-factor authentication for “OneSign” and into the VPNs for “ConfirmID”</li>
            <li>
              Identity proofing process, and SAML (Security Assertion Markup Language) interface for enabling web single
              sign-on for web applications
            </li>
            <li>Native Microsoft SQL encryption and permissions model to prevent unauthorized access</li>
            <li>Data encryption at rest</li>
            <li>Encryption through application access</li>
          </ul>
          <p>
            For on-premises biometrics-enabled products and services, no biometric data will be processed by data
            importer as the only relation to biometric data being a one-way function that is derived from fingerprints
            but cannot be reversed.
          </p>
          <p>
            For GroundControl product and services, data exporter may choose to configure and enable the collection and
            sharing of user passwords, administrator passwords, and Wi-Fi names and passwords. In the event such
            collection and sharing is configured and enabled, Imprivata has implemented the following safeguards:
          </p>
          <ul>
            <li>AES-256 encryption</li>
            <li>One-way cryptographic hash for system login passwords</li>
            <li>Virtual private cloud with network filtering and intrusion detection</li>
          </ul>
          <p>
            <strong>FairWarning</strong>
          </p>
          <p>
            Due to the nature of the product, sensitive information is shared through integrations, user-generated logs,
            and/or other data shared by customer-elected means. Those instances may include the following data:
          </p>
          <ul>
            <li>Health data</li>
          </ul>
          <p>As a standard practice, data importer has implemented the following safeguards:</p>
          <ul>
            <li>Anonymization of usage metrics collection</li>
            <li>Single sign-on (SSO)</li>
            <li>Multi-factor authentication</li>
            <li>
              Identity proofing process, and SAML (Security Assertion Markup Language) interface for enabling SSO for
              web applications
            </li>
            <li>Data encryption at rest</li>
            <li>Site to site VPN required for healthcare industry customer access to the application</li>
          </ul>
          <h5>
            The frequency of the transfer (e.g. whether the data is transferred on a one-off or continuous basis).
          </h5>
          <p>
            <strong>
              <u>
                OneSign, ConfirmID, Identity Governance, GroundControl, FairWarning, Customer Connect, Enterprise Access
              </u>
            </strong>
          </p>
          <p>
            Depending on the product and services, both continuous access to data and one-off instances of processing
            may be provided by data exporter to data importer as mentioned above. Cloud data are continuously
            transferred in real time from any cloud applications that offer real-time streaming. One-off instances of
            processing may be facilitated by data exporter to data importer when requesting support, troubleshooting,
            implementation and/or in batch files, back-ups, etc.
          </p>
          <h5>Nature of the processing</h5>
          <p>
            <strong>
              <u>
                OneSign, ConfirmID, Identity Governance, GroundControl, FairWarning, Customer Connect, Enterprise Access
              </u>
            </strong>
          </p>
          <p>
            Depending on the product and/or service, it may have cloud and/or on-premises components. One-off or
            continuous instances of processing may be sent to data importer as part of support, troubleshooting, and/or
            implementation through the data importer’s support. The support can be provided from the data importer’s
            entities (subprocessors) in the US, UK, or Australia, based on the time of the support request by data
            exporter and availability of support resources. In limited escalation of support or troubleshooting
            inquiries, data may be transferred to third-party subprocessors.
          </p>
          <h5>Purpose(s) of the data transfer and further processing</h5>
          <p>
            <strong>
              <u>
                OneSign, ConfirmID, Identity Governance, GroundControl, FairWarning, Customer Connect, Enterprise Access
              </u>
            </strong>
          </p>
          <p>
            The purpose of the transfer by data exporter and further processing of personal data by data importer is the
            performance of the products and services under the Principal Agreement between data exporter and data
            importer by way of proper delivery of Remote Customer Support Services.
          </p>
          <h5>
            The period for which the personal data will be retained, or, if that is not possible, the criteria used to
            determine that period
          </h5>
          <p>
            <strong>
              <u>
                OneSign, ConfirmID, GroundControl, Identity Governance, FairWarning, Customer Connect, Enterprise Access
              </u>
            </strong>
          </p>
          <p>
            Data are retained for as long as is necessary for product support (term defined by the Principal Agreement)
            or troubleshooting resolution of the support and/or troubleshooting request by data exporter and are deleted
            within a reasonable period thereafter. Where the data exporter has parameters, configurations, or data
            retention schedules, data governed by those parameters are retained for as long as outlined therein.
          </p>
          <h5>
            For transfers to (sub-) processors, also specify subject matter, nature and duration of the processing
          </h5>
          <p>
            Please see <a href="#annex-3">Annex III</a> below for information on Imprivata’s Subprocessors.
          </p>
          <h4>COMPETENT SUPERVISORY AUTHORITY</h4>
          <p>
            The competent Supervisory Authority shall be the DPA in the Netherlands, or the UK ICO for matters related
            to data subjects in the UK.
          </p>
          <p>&nbsp;</p>
          <h3 id="annex-2">
            <u>ANNEX II</u>
          </h3>
          <p>
            <strong>Pseudonymisation and encryption of personal data</strong>
          </p>
          <ul>
            <li>
              For Special Categories of Data and Sensitive Data, Imprivata always encrypts such data both in transit and
              at rest.
            </li>
            <li>
              Imprivata endpoints employ full disk encryption with Trusted Platform Module enabled at the BIOS level.
            </li>
            <li>Imprivata utilizes pseudonymisation where applicable.</li>
          </ul>
          <p>
            <strong>Confidentiality, integrity, availability and resilience of processing systems and services</strong>
          </p>
          <ul>
            <li>
              Imprivata maintains strict separation controls for data. Controller’s data are either stored on
              Controller’s premises or in a unique cloud instance. Access to Controller Personal Data is limited to
              specific individuals based on their core function and its necessity for carrying out the terms of the
              business relationship, i.e. product support. Third party access is limited to Subprocessors. Availability
              is maintained through multiple appliances (on Controller premises) and multiple zones (SaaS service
              provider).
            </li>
            <li>Testing and development environments do not use production data.</li>
            <li>
              Imprivata maintains a rigorous third party management program, which includes onboarding due diligence,
              risk assessments and treatment for security and privacy policies, rules, standards, and regulations. Third
              party access is limited to the nature of the agreement between Imprivata and the third party and is
              revoked promptly upon termination of the relationship. All contracts require compliance with Imprivata
              security and privacy policies.
            </li>
          </ul>
          <p>
            <strong>
              Restoration of the availability and access to personal data in a timely manner in the event of a physical
              or technical incident
            </strong>
          </p>
          <ul>
            <li>
              Imprivata has documented Incident Response, Disaster Recovery, and Business Continuity policies and
              accompanying procedures/plans which are tested annually by an independent third-party service provider.
            </li>
          </ul>
          <p>
            <strong>
              Processes for regularly testing, assessing and evaluating the effectiveness of technical and
              organisational measures in order to ensure the security of the processing
            </strong>
          </p>
          <ul>
            <li>
              Imprivata utilizes a SIEM and MDR service to monitor all activity regarding repositories and systems that
              manage any Special Categories of Data and Sensitive Data to ensure their security and proper use.
            </li>
            <li>
              Imprivata has Data Leak Prevention technology to ensure monitoring and the unauthorized exfiltration of
              data.
            </li>
          </ul>
          <p>
            <strong>User identification and authorisation</strong>
          </p>
          <ul>
            <li>Access to critical Imprivata services is multifactor authentication enabled.</li>
            <li>Imprivata access controls are mapped to NIST SP 800-63B.</li>
          </ul>
          <p>
            <strong>Protection of data during transmission</strong>
          </p>
          <ul>
            <li>Imprivata transmits data using TLS 1.2 or above.</li>
          </ul>
          <p>
            <strong>Protection of data during storage</strong>
          </p>
          <ul>
            <li>
              Imprivata uses Nutanix native encryption. In addition, Imprivata uses immutable storage so that stored
              data cannot be modified, tampered, or removed.
            </li>
            <li>
              Imprivata performs online back-ups to a secondary data center nightly. Back-up systems are isolated from
              active directory accounts and secured with multifactor authentication and are limited to Imprivata IT
              administrators. Back-up media restoration is tested monthly.
            </li>
          </ul>
          <p>
            <strong>Physical security of locations at which personal data are processed</strong>
          </p>
          <ul>
            <li>
              Access controls, mapped to ISO 27001 are in place to ensure only authorized personnel have access to
              network closets, data centers, and other storage facilities.
            </li>
          </ul>
          <p>
            <strong>Events logging</strong>
          </p>
          <ul>
            <li>
              All critical services forward logs to Imprivata SIEM. Events are monitored by Imprivata and a Manage
              Detect and Respond service.
            </li>
          </ul>
          <p>
            <strong>System configuration</strong>
          </p>
          <ul>
            <li>
              Imprivata has documented security hardening guidelines and system security testing policies to provide
              employees standard practices for system configuration.
            </li>
          </ul>
          <p>
            <strong>Internal IT and IT security governance and management</strong>
          </p>
          <ul>
            <li>
              Imprivata maintains robust security and privacy policies and accompanying procedures for all employees,
              contractors, and third parties.
            </li>
            <li>
              Imprivata employs endpoint protection technology designed to protect IT assets from Ransomware, Malware,
              etc. and the security team manages and monitors assets to ensure they are compliant with the security
              policies and requirements.
            </li>
            <li>
              Imprivata has implemented vulnerability scans across all its IT assets. Patch management follows a
              risk-based scale. Imprivata addresses critical and high exploitable vulnerabilities. Imprivata also
              utilizes Thorough Assessment Regarding General Emerging Threat (T.A.R.G.E.T) protocols. These are designed
              to efficiently deal with vulnerabilities that are not part of the CVE database at the time of the finding.
            </li>
            <li>
              Assets associated with information and information processing facilities are identified and an inventory
              of these assets maintained. All Imprivata IT assets have a designated owner who is responsible for the
              asset throughout its lifecycle.
            </li>
          </ul>
          <p>
            <strong>Certification/assurance of processes and products</strong>
          </p>
          <ul>
            <li>
              Imprivata utilizes independent third parties to assess internal protocols as well as products regularly.
              This includes penetration testing, risk assessments, gap analyses, vulnerability testing, and receives
              reports, recommendations, and attestation from these third parties. The attestation includes but is not
              limited to SOC 2 Type 2. In addition, Imprivata engages independent third parties for regulatory
              compliance testing, assessment, evaluation of program effectiveness.
            </li>
          </ul>
          <p>
            <strong>Data minimization</strong>
          </p>
          <ul>
            <li>Imprivata does not collect more Controller Personal Data than necessary for business functions.</li>
          </ul>
          <p>
            <strong>Data quality</strong>
          </p>
          <ul>
            <li>Imprivata maintains up-to-date information as a core business function.</li>
          </ul>
          <p>
            <strong>Data retention</strong>
          </p>
          <ul>
            <li>
              Imprivata does not process Controller Personal Data for longer than necessary or otherwise required by
              law.
            </li>
          </ul>
          <p>
            <strong>Accountability</strong>
          </p>
          <ul>
            <li>Imprivata has a dedicated accountable individual for Security and Privacy practices and procedures.</li>
          </ul>
          <p>
            <strong>Data portability and erasure</strong>
          </p>
          <ul>
            <li>Imprivata products are innately designed for data portability.</li>
            <li>
              Imprivata follows NIST SP 800-88 for the destruction and/or deletion of Controller Personal Data at the
              end of its lifecycle.
            </li>
          </ul>
          <p>
            <strong>Technical and organizational measures for transfers to (sub-) processors</strong>
          </p>
          <ul>
            <li>
              Imprivata imposes relevant Processor duties owed to the Controller onto Subprocessors as described in
              Section 6 of the DPA.
            </li>
          </ul>
          <p>&nbsp;</p>
          <h3 id="annex-3">
            <u>ANNEX III</u>
          </h3>
          <p>
            <strong>The data exporter has authorized the use of the following Subprocessors:</strong>
          </p>
          <table>
            <thead>
              <tr>
                <th>Subprocessor and Location</th>
                <th>Nature of Services</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  Imprivata UK Limited
                  <br />4 Longwalk, Stockley Park, Uxbridge, UB11
                  <br />
                  1FE
                </td>
                <td>24/7 product support and troubleshooting</td>
              </tr>
              <tr>
                <td>
                  Imprivata Australia Pty. Ltd.
                  <br />
                  Level 19 / 644 Chapel St
                  <br />
                  South Yarra, Melbourne VIC 3141
                </td>
                <td>24/7 product support and troubleshooting</td>
              </tr>
              <tr>
                <td>
                  Salesforce
                  <br />1 Market St #300
                  <br />
                  San Francisco, CA 94105
                </td>
                <td>Enterprise Operations</td>
              </tr>
              <tr>
                <td>
                  Crowdstrike
                  <br />
                  150 Mathilda Place, 3rd Floor
                  <br />
                  Sunnyvale, CA 94086
                </td>
                <td>Internal Operations</td>
              </tr>
              <tr>
                <td>
                  Microsoft Corporation
                  <br />
                  One Microsoft Way
                  <br />
                  Redmond, WA 98052
                </td>
                <td>Enterprise Operations</td>
              </tr>
              <tr>
                <td>
                  Amazon Web Services
                  <br />
                  410 Terry Avenue North
                  <br />
                  Seattle WA. 98109
                </td>
                <td>
                  Enterprise Operations
                  <br />
                  Hosting Services
                </td>
              </tr>
              <tr>
                <td>
                  Atlassian Pty Ltd, c/o Atlassian, Inc.
                  <br />
                  350 Bush Street, Level 13
                  <br />
                  San Francisco, CA 94104
                </td>
                <td>Enterprise Operations</td>
              </tr>
              <tr>
                <td>
                  Marketo, Inc.
                  <br />
                  901 Mariners Island Blvd., Suite 200
                  <br />
                  San Mateo, CA 94404
                </td>
                <td>Marketing Operations</td>
              </tr>
              <tr>
                <td>
                  Allego, Inc.
                  <br />
                  117 Kendrick Street, Suite 800
                  <br />
                  Needham, MA 02494
                </td>
                <td>Enterprise Operations</td>
              </tr>
              <tr>
                <td>
                  Zoom Video Communications, Inc.
                  <br />
                  55 Almaden Blvd.
                  <br />
                  San Jose, CA 95113
                </td>
                <td>Enterprise Operations</td>
              </tr>
              <tr>
                <td>
                  Clari Inc.
                  <br />
                  1154 Sonora Court
                  <br />
                  Sunnyvale, CA 94086
                </td>
                <td>Enterprise Operations</td>
              </tr>
              <tr>
                <td>
                  DocuSign, Inc.
                  <br />
                  221 Main Street, Suite 1550
                  <br />
                  San Francisco, CA 94105
                </td>
                <td>Enterprise Operations</td>
              </tr>
              <tr>
                <td>
                  Alphabet Inc. – Google
                  <br />
                  1600 Amphitheatre Parkway
                  <br />
                  Mountain View, CA 94043
                </td>
                <td>Enterprise Operations</td>
              </tr>
              <tr>
                <td>
                  Cornerstone OnDemand, Inc.
                  <br />
                  1601 Cloverfield Blvd. Suite 620 South
                  <br />
                  Santa Monica, CA 90404
                </td>
                <td>Enterprise Operations</td>
              </tr>
              <tr>
                <td>
                  LinkSquares, Inc.
                  <br />
                  60 State St, Suite 1800
                  <br />
                  Boston, MA 02109
                </td>
                <td>Internal Operations</td>
              </tr>
              <tr>
                <td>
                  LinkedIn
                  <br />
                  1000 West Maude Avenue
                  <br />
                  Sunnyvale, CA 94085
                </td>
                <td>Enterprise Operations</td>
              </tr>
              <tr>
                <td>
                  Symantec Corporation (Norton)
                  <br />
                  350 Ellis Street, Building A<br />
                  Mountain View, CA 9404
                </td>
                <td>Internal Operations</td>
              </tr>
              <tr>
                <td>
                  OneTrust
                  <br />
                  1200 Abernathy Rd NE Suite 300
                  <br />
                  Atlanta, GA 30328
                </td>
                <td>Enterprise Operations</td>
              </tr>
              <tr>
                <td>
                  Oracle Corporation
                  <br />
                  2300 Oracle Way
                  <br />
                  Austin, TX 78741
                </td>
                <td>Internal Operations</td>
              </tr>
              <tr>
                <td>
                  Zendesk, Inc.
                  <br />
                  989 Market Street
                  <br />
                  San Francisco, CA 94103
                </td>
                <td>Enterprise Operations</td>
              </tr>
              <tr>
                <td>
                  Dropbox, Inc.
                  <br />
                  1800 Owens St
                  <br />
                  San Francisco, CA 94158
                </td>
                <td>Enterprise Operations</td>
              </tr>
              <tr>
                <td>
                  Snowflake Inc.
                  <br />
                  106 E. Babcock Street, Suite 3A,
                  <br />
                  Bozeman, MT 59715
                </td>
                <td>Internal Operations</td>
              </tr>
              <tr>
                <td>
                  Sisense Ltd.
                  <br />
                  1359 Broadway, 4th Fl,
                  <br />
                  New York NY 10018
                </td>
                <td>Internal Operations</td>
              </tr>
              <tr>
                <td>
                  OGiTiX Software AG
                  <br />
                  Hans-Böckler-Straße 12
                  <br />
                  40764 Langenfeld (Rheinland), Germany
                </td>
                <td>Enterprise Operations</td>
              </tr>
              <tr>
                <td>
                  Highspot, Inc.
                  <br />
                  2211 Elliott Ave., Suite 400
                  <br />
                  Seattle, WA 98121
                </td>
                <td>Enterprise Operations</td>
              </tr>
              <tr>
                <td>
                  Confluent, Inc.
                  <br />
                  101 University Avenue, Suite 111
                  <br />
                  Palo Alto, CA 94301
                </td>
                <td>Enterprise Operations</td>
              </tr>
            </tbody>
          </table>
          <p>
            Imprivata also uses a number of software providers, network providers, contractors, and consultants that, in
            instances where services and/or support is provided, may be able to access Imprivata’s systems and
            incidentally see personal data. Imprivata will provide the most current list of Subprocessors upon request.
          </p>
        </div>
      </div>
    </>
  );
};

export default DataProcessingAddendum;
