// Copyright 2022, Imprivata, Inc.  All rights reserved.

import type { InitOptions } from 'i18next';
import HttpBackend from 'i18next-http-backend';
import LocalResourcesBackend from 'i18next-resources-to-backend';
import getConfig from '../appEnvConfig';
import { resources, defaultNS } from './resources';

const { PUBLIC_URL } = getConfig();

const options: InitOptions = {
  defaultNS,
  fallbackLng: 'en',
  debug: process.env.NODE_ENV === 'development',

  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },

  backend: {
    backends: [HttpBackend, LocalResourcesBackend(resources)],
    backendOptions: [
      {
        // If allowMultiLoading is false, lngs and namespaces will have only one element each,
        // If allowMultiLoading is true, lngs and namespaces can have multiple elements
        loadPath: `${PUBLIC_URL || ''}/locales/{{lng}}/{{ns}}.json`,

        // path to post missing resources, or a function
        // function(lng, namespace) { return customPath; }
        // the returned path will interpolate lng, ns if provided like giving a static path
        addPath: undefined,
      },
    ],
  },

  react: {
    useSuspense: false,
    transSupportBasicHtmlNodes: true,
  },
};

export default options;
