// Copyright 2022, Imprivata, Inc.  All rights reserved.

const KEY_PREFIX = "initialSetupStorage";

const storage = {
  getItem: (key: string): string | null => sessionStorage.getItem(fullKey(key)),

  setItem: (key: string, value: string): void => {
    sessionStorage.setItem(fullKey(key), value);
    window.localStorage.setItem(fullKey(key), value);
  },

  removeItem: (key: string): void => sessionStorage.removeItem(fullKey(key)),

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  clear: (): void => sessionStorage.clear(),

  isEmpty: (): boolean => sessionStorage.length === 0,
};

export function fullKey(key: string): string {
  return `${KEY_PREFIX}/${key}`;
}

export default storage;
