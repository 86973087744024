// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Banner, destroyBanner } from '@imprivata-cloud/astra-ui-component-library';
import { clearAADLinkError, getConsentUrl } from '../store/initialSetup/actions';
import type { GetAADLinkParams } from '../store/initialSetup/types';
import { aadLinkPendingSelector, aadLinkRequestErrorSelector } from '../store/initialSetup/selectors';
import type { ApiError } from '../service/types';
import { StorageKeys } from '../constants/fieldNames';
import storage from '../utils/storage';
import rxClient from '../service/rxClient';
import { getTenantHeaderInterceptor } from '../service/interceptors';

export function useAADLinkRequest(): {
  pending: boolean;
  error: ApiError | null;
  onSubmit(values: GetAADLinkParams): void;
} {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const pending = useSelector(aadLinkPendingSelector);
  const error = useSelector(aadLinkRequestErrorSelector);
  const onSubmit = useCallback(
    (values: GetAADLinkParams) => {
      storage.setItem(StorageKeys.AAD_TENANT_ID, values.aadTenantId);
      dispatch(getConsentUrl.request(values));
    },
    [dispatch],
  );

  useEffect(() => {
    const clearError = () => {
      dispatch(clearAADLinkError());
    };
    const destroy = () => destroyBanner();

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        destroy();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    const tenantId = storage.getItem(StorageKeys.TENANT_ID);
    if (tenantId) {
      rxClient.client.interceptors.request.use(getTenantHeaderInterceptor(tenantId));
    }

    if (error) {
      Banner({
        type: 'error',
        message: t('messages.errors.bad-tenant-primary' as never),
        description: t('messages.errors.bad-tenant-secondary' as never),
        onClose: clearError,
        onClick: clearError,
        duration: 10,
        datatestid: 'aad-tenant-error',
      });
    } else {
      destroy();
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      destroy();
    };
  }, [error, dispatch, t]);

  return useMemo(
    () => ({
      pending,
      error,
      onSubmit,
    }),
    [pending, error, onSubmit],
  );
}
