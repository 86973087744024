import type { URLConfig } from '../appEnvConfig';
import { _getUrls, type Replacement, updateUrl } from '@imprivata-cloud/common';

export function getUpdatedUrls(): URLConfig {
  const url = window.location.host;
  const { apiUrl, tracingUrl } = _getUrls();
  const appURLs: Record<string, Replacement> = {
    adminUrl: { sub: 'admin.app', path: '/users' },
  };
  const updates = updateUrl(url, appURLs, 'https://');
  const adminUrl = updates.adminUrl;

  return { apiUrl, adminUrl, tracingUrl };
}
