// Copyright 2022, Imprivata, Inc.  All rights reserved.

import axios, { type AxiosError } from 'axios';
import { type ErrorResponseBody, type ApiError, ApiErrorType } from './types';

interface ErrorImplOptions {
  axiosError?: AxiosError<ApiError>;
  apiError?: ApiError;
}

export default class ErrorImpl extends Error implements ApiError {
  private readonly _axiosError?: AxiosError<ApiError>;
  private readonly _apiError?: ApiError;

  constructor(message?: string, options: ErrorImplOptions = {}) {
    const { axiosError, apiError } = options;
    super(message);

    this._axiosError = axiosError;
    this._apiError = apiError;
  }

  get httpStatus(): number {
    if (this._axiosError) {
      if (this._axiosError.response) {
        return this._axiosError.response.status;
      }
      if (this._axiosError.request) {
        return this._axiosError.request.status;
      }
    }

    return 0;
  }

  get code(): string {
    return this._apiError?.code || 'UNKNOWN';
  }

  get type(): ApiErrorType {
    return this._apiError?.type || ApiErrorType.APP;
  }

  static from(error: AxiosError<ErrorResponseBody> | Error): ErrorImpl {
    if (axios.isAxiosError(error)) {
      const apiError = error?.response?.data?.error;
      if (apiError) {
        return new ErrorImpl(error.message, {
          axiosError: error,
          apiError: apiError,
        });
      }
      return new ErrorImpl(error.message, { axiosError: error });
    }
    return new ErrorImpl(error.message);
  }
}
