// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { combineEpics, type Epic } from 'redux-observable';
import { filter, mapTo, take, tap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import type { RootAction } from '../rootAction';
import ErrorImpl from '../../service/errorImpl';

import { HTTP_STATUS_SERVICE_GONE } from '../../constants/services';
import { reloadAppPageConfirm, serviceGoneSignal } from './actions';

export const serviceGoneEpic: Epic<RootAction> = (action$) =>
  action$.pipe(
    filter((action) => action.payload instanceof ErrorImpl && action.payload.httpStatus === HTTP_STATUS_SERVICE_GONE),
    mapTo(serviceGoneSignal()),
  );

export const reloadPageEpic: Epic<RootAction> = (action$) =>
  action$.pipe(
    filter(isActionOf(reloadAppPageConfirm)),
    take(1),
    tap({
      next: () => {
        // Reload the page

        window.location.reload();
      },
    }),
  );

export default combineEpics(serviceGoneEpic, reloadPageEpic);
