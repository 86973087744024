// Copyright 2022, Imprivata, Inc.  All rights reserved.

import type React from 'react';
import { useEffect } from 'react';
import { I18nextProvider } from 'react-i18next';
import { Provider as ReduxProvider } from 'react-redux';
import { Router } from 'react-router-dom';
import AppLayout from './containers/AppLayout';
import ConfigProvider from './containers/ConfigProvider';
import i18next from './i18n';
import history from './routes/history';
import { applyInterceptors } from './service/interceptors';
import { SpanNames, tracer } from './tracing';
import { getBuildInfo } from './utils/build';
import { configureStore } from './store/createStore';

const store = configureStore();
applyInterceptors(store.dispatch);
let notified = false;
const APP_VERSION = getBuildInfo();

const App: React.FC = function App() {
  if (!notified) {
    console.debug('APP VERSION [authn]: ', APP_VERSION);
    notified = true;
  }

  useEffect(() => {
    tracer.startSpan(SpanNames.INIT_APP, { version: APP_VERSION });

    return () => {
      try {
        tracer.endSpan(SpanNames.INIT_APP, { version: APP_VERSION });
      } catch (_) {
        /**  */
      }
    };
  }, []);

  return (
    <ReduxProvider store={store}>
      <I18nextProvider i18n={i18next}>
        <ConfigProvider>
          <Router history={history}>
            <AppLayout />
          </Router>
        </ConfigProvider>
      </I18nextProvider>
    </ReduxProvider>
  );
};

export default App;
