// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { useCallback, useEffect, useMemo } from 'react';
import { type TFunction, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import type { Dispatch } from 'redux';
import {
  Banner,
  destroyBanner,
} from '@imprivata-cloud/astra-ui-component-library';
import { clearAdminAuthError, dpaSubmit } from '../store/initialSetup/actions';
import {
  adminAuthErrorSelector,
  adminAuthPendingSelector,
  dpaCheckErrorSelector,
  dpaSubmitErrorSelector,
} from '../store/initialSetup/selectors';
import type { AdminAuthParams } from '../store/initialSetup/types';
import { adminUsernameField } from '../constants/fieldNames';

import { BANNER_DURATION } from '../constants/misc';
import { DPA_DOCUMENT_NAME } from '../components/DataProcessingAddendum';

export const useAdminCredsRequest = (): {
  pending: boolean;
  onSubmit(values: AdminAuthParams): void;
} => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const pending = useSelector(adminAuthPendingSelector);
  const adminAuthError = useSelector(adminAuthErrorSelector);
  const dpaCheckError = useSelector(dpaCheckErrorSelector);
  const dpaSubmitError = useSelector(dpaSubmitErrorSelector);
  const onSubmit = useCallback(
    (values: AdminAuthParams) => {
      dispatch(
        dpaSubmit.request({
          grantType: 'granted',
          documentName: DPA_DOCUMENT_NAME,
          userId: values[adminUsernameField],
        }),
      );
    },
    [dispatch],
  );

  useEffect(() => {
    const destroy = () => destroyBanner();

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        destroy();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    const TRY_AGAIN_MSG = t('messages.errors.try-again');
    if (adminAuthError) {
      errorBanner(
        t('messages.errors.auth-fail-primary'),
        TRY_AGAIN_MSG,
        dispatch,
      );
    } else if (dpaCheckError) {
      errorBanner(
        t('messages.errors.dpa-check-fail-primary'),
        TRY_AGAIN_MSG,
        dispatch,
      );
    } else if (dpaSubmitError) {
      errorBanner(
        t('messages.errors.dpa-submit-fail-primary'),
        TRY_AGAIN_MSG,
        dispatch,
      );
    } else {
      destroy();
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      destroy();
    };
  }, [dpaCheckError, dpaSubmitError, adminAuthError, dispatch, t]);

  return useMemo(
    () => ({
      pending,
      onSubmit,
    }),
    [pending, onSubmit],
  );
};

function errorBanner(msg: string, desc: string, dispatch: Dispatch) {
  function clearError() {
    dispatch(clearAdminAuthError());
  }
  Banner({
    type: 'warning',
    message: msg,
    description: desc,
    onClose: clearError,
    onClick: clearError,
    duration: BANNER_DURATION,
    datatestid: 'admin-auth-error',
  });
}

export function showInvalidEmailBanner(t: TFunction): void {
  Banner({
    type: 'error',
    message: t('messages.validation.types.email'),
    description: t('messages.errors.try-again'),
    duration: BANNER_DURATION,
    datatestid: 'banner-invalid-email-format',
  });
}

// Simplified check is enough in our case
export function validateEmail(email: string): boolean {
  return /\S+@\S+\.\S+/.test(email);
}
