// Copyright 2022, Imprivata, Inc.  All rights reserved.

import type { DirectionType } from 'antd/lib/config-provider';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export function useTranslatedTitle(): void {
  const { t, ready } = useTranslation();

  useEffect(() => {
    if (ready) {
      document.title = t('common.app-title');
    }
  }, [t, ready]);
}

export function useValidationMessages(): Record<string, unknown> {
  const { t, ready } = useTranslation();

  return ready ? t('messages.validation', { returnObjects: true }) : {};
}

export function useLanguageDir(): DirectionType {
  const { i18n } = useTranslation();

  return i18n.dir();
}
