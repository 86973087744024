// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { startSetup, setSessionId, invalidateSession } from '../store/initialSetup/actions';
import type { StartSetupParams } from '../store/initialSetup/types';
import {
  startSetupPendingSelector,
  startSetupErrorSelector,
  startSetupSessionSelector,
} from '../store/initialSetup/selectors';
import type { ApiError } from '../service/types';
import { bearerTokenParam, tenantIdParam, StorageKeys } from '../constants/fieldNames';
import useQueryParams from './useQueryParams';
import storage from '../utils/storage';
import rxClient from '../service/rxClient';
import { getTenantHeaderInterceptor } from '../service/interceptors';

export function useStartSetupRequest(): {
  pending: boolean;
  error: ApiError | null;
} {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const pending = useSelector(startSetupPendingSelector);
  const error = useSelector(startSetupErrorSelector);
  const sessionId = useSelector(startSetupSessionSelector);

  const params = useQueryParams();
  const bearerToken = (params[bearerTokenParam] as string) || '';
  const tenantId = (params[tenantIdParam] as string) || '';

  useEffect(() => {
    const setupParams: StartSetupParams = { bearerToken };
    storage.setItem(StorageKeys.TENANT_ID, tenantId);

    rxClient.client.interceptors.request.use(getTenantHeaderInterceptor(tenantId));

    dispatch(startSetup.request(setupParams));
  }, [dispatch, bearerToken, tenantId]);

  useEffect(() => {
    if (sessionId) {
      dispatch(setSessionId(sessionId));
    }
  }, [dispatch, sessionId]);

  useEffect(() => {
    if (error) {
      dispatch(invalidateSession());
    }
  }, [error, dispatch]);

  return useMemo(
    () => ({
      pending,
      error,
    }),
    [pending, error],
  );
}
